<template>
    <header>
        <img class="logo-image" src="/logo.webp" alt="A.S.D. Riccione AVIS Logo" />
        <h1>
            <p>Gruppo clclistico</p>
            <img src="/title.webp" alt="A.S.D. Riccione AVIS" />
            <p>Dal 1975</p>
        </h1>
        <img class="group-image" src="/title_group.webp" alt="Foto di gruppo" />
    </header>
</template>

<style scoped>
header {
    display: none;
}

@media screen and (width >= 768px) {
    header {
        margin: var(--m-large);
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: var(--m-large);
    }

    header, img {
        max-height: 200px;
    }

    h1 {
        display: grid;
        grid-template-rows: 1fr max-content 1fr;
        max-width: 768px;
    }

    h1 > p {
        font-weight: 500;
    }

    h1 > p:first-child {
        margin-top: auto;
        font-size: var(--font-size-smaller);
    }

    h1 > p:last-child {
        font-size: var(--font-size-small);
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }

    .logo-image {
        display: inline-block;
        aspect-ratio: 1/1;
    }

    .group-image {
        display: none;
    }
}

@media screen and (width >= 1280px) {
    header {
        grid-template-columns: auto 1fr auto;
    }

    .group-image {
        display: inline-block;
        margin-block: auto;
    }
}
</style>